import 'ol/ol.css';
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol-ext/dist/ol-ext.css";

import Map from 'ol/Map';
import View from 'ol/View';
import { transform } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import TileWMS from 'ol/source/TileWMS';
import BingMaps from 'ol/source/BingMaps';
import SourceOSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import Static from 'ol/source/ImageStatic';
import GeoJSON from 'ol/format/GeoJSON';
import {Fill, Stroke, Style, Text} from 'ol/style';
import VectorSource from 'ol/source/Vector.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {getCenter} from 'ol/extent';
import Link from 'ol/interaction/Link';
import {useGeographic} from 'ol/proj';
import SearchNominatim from 'ol-ext/control/SearchNominatim';
import GeolocationButton from 'ol-ext/control/GeolocationButton';
import Overlay from 'ol/Overlay';

import Projection from 'ol/proj/Projection.js';
import proj4 from 'proj4';
import {toLonLat} from 'ol/proj.js';
import {register} from 'ol/proj/proj4.js';
import TileGrid from 'ol/tilegrid/TileGrid.js';

import LayerSwitcher from 'ol-layerswitcher';

import {Control, defaults as defaultControls} from 'ol/control.js';

import EsriJSON from 'ol/format/EsriJSON.js';
import {tile as tileStrategy} from 'ol/loadingstrategy.js';
import {createXYZ} from 'ol/tilegrid.js';

useGeographic();

const serviceUrl =
  'https://gisdata.in.gov/server/rest/services/' +
  'Hosted/Parcel_Boundaries_of_Indiana_Current/FeatureServer/';
const layer = '0';

  const parcelStyle = new Style({
    stroke: new Stroke({
      color: [255, 0, 0, 1],
      width: 0.5,
    }),
  });

// Indiana Projection
proj4.defs("ESRI:102673","+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +datum=NAD83 +units=us-ft +no_defs +type=crs");
register(proj4);

var beaconGrid = new TileGrid({
    maxZoom: 17,
    origin: [-18119800, 20012700],
    resolutions: [660, 550, 440, 385, 330, 275, 220, 165, 110, 55, 27.5, 13.75, 6.875, 4.166666666667, 2.083333333333, 1.041666666667, 0.520833333333, 0.260416666667],
})

var beaconGrid2 = new TileGrid({
    maxZoom: 17,
    origin: [0, 100000000],
    resolutions: [660, 550, 440, 385, 330, 275, 220, 165, 110, 55, 27.5, 13.75, 6.875, 4.166666666667, 2.083333333333, 1.041666666667, 0.520833333333, 0.260416666667],
})

class homeControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.title = "Go Home";
    button.innerHTML = '<img height="100%" src="https://mapstatic.millhousen.com/assets/home.svg">';

    const element = document.createElement('div');
    element.className = 'home-button ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleHome.bind(this), false);
    button.addEventListener('dblclick', this.handleSetHome.bind(this), false);
  }

  handleSetHome() {
    const view = map.getView();
    view.setCenter([-85.49914, 39.16392]);
    view.setZoom(17);
    view.setRotation(0);
  }
  handleHome() {
    const view = map.getView();
    view.setCenter([-85.43384, 39.21061]);
    view.setZoom(17);
    view.setRotation(0);

  }
}

class OSMControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
      const options = opt_options || {};
  
      const button = document.createElement('button');
      button.title = "Open in OpenStreetMap";
      button.innerHTML = '<img height="100%" src="https://mapstatic.millhousen.com/assets/openstreetmap.svg">';
  
      const element = document.createElement('div');
      element.className = 'osm-button ol-unselectable ol-control';
      element.appendChild(button);
  
      super({
        element: element,
        target: options.target,
      });
  
      button.addEventListener('click', this.handleOSM.bind(this), false);
    }
  
    handleOSM() {
        const view = map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
      window.open('https://www.openstreetmap.org/#map='+ zoom +'/' + center[1] + '/' + center[0], '_blank').focus();
    }
  }


class MapillaryControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
      const options = opt_options || {};
  
      const button = document.createElement('button');
      button.title = "Open in Mapillary";
      button.innerHTML = '<img height="100%" src="https://mapstatic.millhousen.com/assets/mapillary.svg">';
  
      const element = document.createElement('div');
      element.className = 'mapillary-button ol-unselectable ol-control';
      element.appendChild(button);
  
      super({
        element: element,
        target: options.target,
      });
  
      button.addEventListener('click', this.handleMapillary.bind(this), false);
    }
  
    handleMapillary() {
        const view = map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
      window.open('https://www.mapillary.com/app/?lat=' + center[1] + '&lng=' + center[0] + '&z=' + zoom, '_blank').focus();
    }
  }

  class GMapsControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
      const options = opt_options || {};
  
      const button = document.createElement('button');
      button.title = "Open in Google Maps";
      button.innerHTML = '<img height="100%" src="https://mapstatic.millhousen.com/assets/googlemaps.svg">';
  
      const element = document.createElement('div');
      element.className = 'gmaps-button ol-unselectable ol-control';
      element.appendChild(button);
  
      super({
        element: element,
        target: options.target,
      });
  
      button.addEventListener('click', this.handleGMaps.bind(this), false);
    }
  
    handleGMaps() {
        const view = map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
      window.open('https://www.google.com/maps/@' + center[1] + ',' + center[0] + ',' + zoom + 'z', '_blank').focus();
    }
  }

// Search control
const search = new SearchNominatim();
// Move to the position on selection in the control list
search.on('select', function (e) {
    // console.log(e);
    map.getView().animate({
        center: toLonLat(e.coordinate),
        zoom: Math.max(map.getView().getZoom(), 17)
    });
});

var map = new Map({
    controls: defaultControls().extend([new MapillaryControl()]),
    target: 'map',
    layers: [
        new LayerGroup({
            'title': 'Base maps',
            layers: [
            new TileLayer({
                    title: 'USGS 1998',
                    type: 'base',
                    extent: [-88.0952557, 37.7705156, -84.6881968, 41.7579125],
                    source: new TileWMS({
                      url: 'https://di-ingov.img.arcgis.com/arcgis/services/DynamicWebMercator/NRCS_1998/ImageServer/WMSServer',
                      attributions: '<a href="https://imagery.gio.in.gov/" target="_blank">IndianaMap</a>',
                      params: {
                        'LAYERS': 0,
                        'TILED': true,
                        'VERSION': "1.3.0"
                        }
                    })
            }),
            new TileLayer({
                title: 'IndianaMap 2005',
                type: 'base',
                extent: [-88.0952557, 37.7705156, -84.6881968, 41.7579125],
                source: new TileWMS({
                  url: 'https://di-ingov.img.arcgis.com/arcgis/services/DynamicWebMercator/Indiana_2005_Imagery/ImageServer/WMSServer',
                  attributions: '<a href="https://imagery.gio.in.gov/" target="_blank">IndianaMap</a>',
                  params: {
                    'LAYERS': 0,
                    'TILED': true,
                    'VERSION': "1.3.0"
                    }
                })
        }),
                new TileLayer({
                    title: 'IndianaMap 2012',
                    type: 'base',
                    extent: [-88.0952557, 37.7705156, -84.6881968, 41.7579125],
                    source: new TileWMS({
                      url: 'https://di-ingov.img.arcgis.com/arcgis/services/DynamicWebMercator/Indiana_2012_Imagery/ImageServer/WMSServer',
                      attributions: '<a href="https://imagery.gio.in.gov/" target="_blank">IndianaMap</a>',
                      params: {
                        'LAYERS': 'Indiana_2012_Imagery%3ANone',
                        'TILED': true,
                        'VERSION': "1.3.0"
                        }
                    })
            }),
                new TileLayer({
                        title: 'IndianaMap 2017',
                        type: 'base',
                        extent: [-88.1296687, 37.7558263, -84.6483203, 41.7885827],
                        source: new TileWMS({
                          url: 'https://di-ingov.img.arcgis.com/arcgis/services/DynamicWebMercator/Indiana_2016_2019_Imagery/ImageServer/WMSServer',
                          attributions: '<a href="https://imagery.gio.in.gov/" target="_blank">IndianaMap</a>',
                          params: {
                            'LAYERS': 'Indiana_2016_2019_Imagery%3ANone',
                            'TILED': true,
                            'VERSION': "1.3.0"
                            }
                        })
                }),
                new TileLayer({
                  title: 'IndianaMap 2023',
                  type: 'base',
                  extent: [-88.1296687, 37.7558265, -84.6985666, 41.8130539],
                  source: new TileWMS({
                    url: 'https://di-ingov.img.arcgis.com/arcgis/services/DynamicWebMercator/Indiana_Current_Imagery/ImageServer/WMSServer',
                    attributions: '<a href="https://imagery.gio.in.gov/" target="_blank">IndianaMap</a>',
                    params: {
                      'LAYERS': 0,
                      'TILED': true,
                      'VERSION': "1.3.0"
                      }
                  })
          }),
                new TileLayer({
                    title: "ESRI Imagery",
                    type: 'base',
                    source: new XYZ ({
                        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        attributions: '<a href="https://maps.google.com" target="_blank">Google</a>',
                        maxZoom: 19
                    })
                }),
                new TileLayer({
                    title: 'Bing Imagery',
                    type: 'base',
                    source: new BingMaps({
                        key: 'Aubq-fee16cj4ggBXEDsaqkDVIr9A0wJFr0B0zCNPszADliqFzWWguD1Tfb0lJSb',
                        imagerySet: 'Aerial',
                        maxZoom: 19
                    })
                }),
                new TileLayer({
                    title: "Google Imagery",
                    type: 'base',
                    source: new XYZ ({
                        url: 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
                        attributions: '<a href="https://maps.google.com" target="_blank">Google</a>'
                    })
                }),
                new TileLayer({
                    title: "Jennings GIS 2022",
                    type: 'base',
                    attributions: 'Jennings County GIS',
                    source: new XYZ ({
                        url: 'https://jenningsin.wthgis.com/tgis/tilesvc.ashx?L=10739&Z={z}&X={x}&Y={y}',
                        maxZoom: 19,
                        minZoom: 7
                    })
                }),
                new TileLayer({
                    title: "Ripley GIS 2020",
                    type: 'base',
                    attributions: 'Ripley County GIS',
                    source: new XYZ ({
                        url: 'https://ripleyin.wthgis.com/tgis/tilesvc.ashx?L=9495&Z={z}&X={x}&Y={y}',
                        maxZoom: 19,
                        minZoom: 7
                    })
                }),
                new TileLayer({
                    title: "Decatur GIS 2024",
                    type: 'base',
                    attributions: 'Decatur County GIS',
                    source: new XYZ ({
                        url: 'https://beacon.schneidercorp.com/Services/ArcGISTile/29477/tile/{z}/{y}/{x}',
                        projection: 'ESRI:102673',
                        tileGrid: beaconGrid
                    })
                }),
                new TileLayer({
                    title: "Decatur GIS 2022",
                    type: 'base',
                    attributions: 'Decatur County GIS',
                    source: new XYZ ({
                        url: 'https://beacon.schneidercorp.com/Services/ArcGISTile/24678/tile/{z}/{y}/{x}',
                        projection: 'ESRI:102673',
                        tileGrid: beaconGrid
                    })
                }),
                new TileLayer({
                    title: "Decatur GIS 2020",
                    type: 'base',
                    attributions: 'Decatur County GIS',
                    source: new XYZ ({
                        url: 'https://beacon.schneidercorp.com/Services/ArcGISTile/19076/tile/{z}/{y}/{x}',
                        projection: 'ESRI:102673',
                        tileGrid: beaconGrid
                    })
                }),
                new TileLayer({
                    title: "Decatur GIS 2018",
                    type: 'base',
                    attributions: 'Decatur County GIS',
                    source: new XYZ ({
                        url: 'https://beacon.schneidercorp.com/Services/ArcGISTile/11717/tile/{z}/{y}/{x}',
                        projection: 'ESRI:102673',
                        tileGrid: beaconGrid2
                    })
                }),
                new TileLayer({
                    title: "Decatur GIS 2016",
                    type: 'base',
                    attributions: 'Decatur County GIS',
                    source: new XYZ ({
                        url: 'https://beacon.schneidercorp.com/Services/ArcGISTile/4307/tile/{z}/{y}/{x}',
                        projection: 'ESRI:102673',
                        tileGrid: beaconGrid2
                    })
                }),
                new TileLayer({
                    title: "USGS Topo",
                    type: 'base',
                    source: new XYZ ({
                        url: 'https://caltopo.s3.amazonaws.com/topo/{z}/{x}/{y}.png',
                        attributions: 'USGS, caltopo.com'
                    })
                }),
                new TileLayer({
                        title: 'USGS Modern Topo',
                        type: 'base',
                        extent: [-88.1296687, 37.7558263, -84.6483203, 41.7885827],
                        source: new TileWMS({
                          url: 'https://basemap.nationalmap.gov/arcgis/services/USGSTopo/MapServer/WMSServer',
                          attributions: '<a href="https://www.usgs.gov/programs/national-geospatial-program/national-map" target="_blank">USGS</a>',
                          params: {
                            'LAYERS': 0,
                            'TILED': true,
                            'VERSION': "1.3.0"
                            }
                        })
                }),
                new TileLayer({
                    title: "Google Maps",
                    type: 'base',
                    source: new XYZ ({
                        url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
                        attributions: '<a href="https://maps.google.com" target="_blank">Google</a>'
                    })
                }),
                new TileLayer({
                    title: 'OSM',
                    type: 'base',
                    visible: true,
                    source: new SourceOSM()
                }),
            ]
        }),
        new TileLayer({
            title: "Drone Footage",
            visible: false,
            source: new XYZ ({
                url: 'https://mapstatic.millhousen.com/layers/drone/tiles/{z}/{x}/{y}.png',
                attributions: 'Kenny Stier'
            })
        }),
        new ImageLayer({
            title: "HA 1955",
            visible: false,
            source: new Static({
              attributions: '© <a href="https://www.historicaerials.com/viewer">Historic Aerials</a>',
              url: 'https://mapstatic.millhousen.com/layers/HA1955_3.png',
              projection: 'EPSG:3857',
              alwaysInRange: true,
              imageExtent: [-9511648.987756, 4750649.197048, -9509033.251460, 4753247.100864]
            })
          }),
        new ImageLayer({
            title: "IGS 1940",
            visible: false,
            source: new Static({
              attributions: '© <a href="https://igws.indiana.edu/IHAPI/">Indiana Geological Survey</a>',
              url: 'https://mapstatic.millhousen.com/layers/IGS1940_2.png',
              projection: 'EPSG:3857',
              alwaysInRange: true,
              imageExtent: [-9513414.049858, 4749861.548063, -9506381.123821, 4755603.631671]
            })
          }),
          new ImageLayer({
            title: "Plat Map 1882",
            visible: false,
            source: new Static({
              attributions: 'Decatur County 1882 Atlas',
              url: 'https://mapstatic.millhousen.com/layers/PlatMap1882_4.png',
              projection: 'EPSG:3857',
              alwaysInRange: true,
              imageExtent: [-9511215.018047, 4751063.270243, -9509341.905130, 4752507.451813]
            })
          }),
          new VectorLayer({
            title: 'Street Names',
            declutter: true,
            visible: false,
            source: new VectorSource({
              format: new GeoJSON(),
              url: 'https://mapstatic.millhousen.com/layers/streets.geojson',
            }),
            style: function (feature) {
              style.getText().setText(feature.get('old_name'));
              return style;
            },
          }),
          new VectorLayer({
            title: 'Parcels',
            visible: false,
            minZoom: 13,
            source: new VectorSource({
                format: new EsriJSON(),
                url: function (extent, resolution, projection) {
                  // ArcGIS Server only wants the numeric portion of the projection ID.
                  const srid = projection
                    .getCode()
                    .split(/:(?=\d+$)/)
                    .pop();
              
                  const url =
                    serviceUrl +
                    layer +
                    '/query/?f=json&' +
                    'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                    encodeURIComponent(
                      '{"xmin":' +
                        extent[0] +
                        ',"ymin":' +
                        extent[1] +
                        ',"xmax":' +
                        extent[2] +
                        ',"ymax":' +
                        extent[3] +
                        ',"spatialReference":{"wkid":' +
                        srid +
                        '}}'
                    ) +
                    '&geometryType=esriGeometryEnvelope&inSR=' +
                    srid +
                    '&outFields=*' +
                    '&outSR=' +
                    srid;
              
                  return url;
                },
                strategy: tileStrategy(
                    createXYZ({
                      tileSize: 512,
                    })
                  ),
                attributions:
                  '<a href="https://www.in.gov/gis/">Indiana Geographic Information Office</a>)',
              }),
            style: function (feature) {
              style.getText().setText(feature.get('old_name'));
              return parcelStyle;
            },
          }),
    ],
    view: new View({
    center: [-85.43384, 39.21061],
    zoom: 17,
    constrainResolution: true
    })
});

const style = new Style({
    text: new Text({
      font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
      placement: 'line',
      fill: new Fill({
        color: 'white',
      }),
    }),
  });

var layerSwitcher = new LayerSwitcher();
var gmapsbutton = new GMapsControl();
var osmbutton = new OSMControl();
var homebutton = new homeControl();
map.addControl(layerSwitcher);
map.addControl(homebutton);
map.addControl(osmbutton);
map.addControl(gmapsbutton);
map.addInteraction(new Link());
map.addControl(search);
